.pdf-download {
    margin-top:10px;
    margin-bottom: 20px;
    width:200px;
    padding: 10px;
    display:flex;
    justify-self: flex-end;
    justify-content: center;
    background-color: red;
    color:white;
    border-radius: 5px;

}